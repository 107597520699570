import styled, { css } from 'styled-components';

import {
    Caption
} from 'components/elements'
import {
    sizeWideScreen,
    sizeXlDevice,
    sizeLaptopLg,
    sizeTabletLg,
    sizeLaptopMd,
    sizeMobileLg,
    sizeDesktopSm,
    sizeDesktopMd
} from 'components/devices'

export const CommonWrap = styled.div`
    h3, h2, h4, h5, h6 {
        margin-bottom: 25px !important;
    }
    h6 {
        font-size: 1.25em;
        ${sizeTabletLg(`
            font-size: 1.25em;
        `)}
    }
    h3 {
        font-size: 2em;
        ${sizeLaptopLg(`
            font-size: 1.7em;
        `)}
    }

    p {
        margin-bottom: 1.25em;
    }

    blockquote {
        margin: 0;
        font-size: 3em;
        ${sizeLaptopMd(`
            font-size: 2em;
        `)}
        ${sizeMobileLg(`
            font-size: 1.5em;
        `)}
        p, b, i {
            font-size: inherit;       
        }
        p {
            margin-bottom: 0;
        }
    }
`;

export const DetailWrap = styled(CommonWrap)`
    max-width: 900px;
    margin-bottom: 35px;
    margin: 0 auto 35px;
    ${Caption} {
        opacity: 65;

        /* For 4k Device */
        ${sizeXlDevice(`
            font-size: 0.8vw;
        `)}
    }
    blockquote {
        margin: 30px 0;
        margin-left: -16%;
        width: 117%;

        ${sizeMobileLg(`
            margin: 15px 0;
        `)}
        ${sizeLaptopLg(`
            margin-left: -13%;
        `)}
        ${sizeLaptopMd(`
            margin-left: 0;
            width: 100%;
        `)}
    }

    /* For 4k Device */
    ${sizeXlDevice(`
        max-width: 900px;
    `)}

    ${sizeWideScreen(`
        max-width: 900px !important;
        width: 100%;
        margin: 0 auto;
        box-sizing: border-box;
    `)}
`;

export const Sections = styled(CommonWrap)`
    width: 100%;
    display: flex;
    flex-flow: column;
    margin-bottom: 35px;
    max-width: 1300px;
    margin: 0 auto;


    ${Caption} {
        opacity: 0.5;

        /* For 4k Device */
        ${sizeXlDevice(`
            font-size: 0.8vw;
        `)}
    }

    /* For 4k Device */
   ${sizeXlDevice(`
        max-width: 100%;
        max-width: 1300px;
    `)}

    ${sizeWideScreen(`
        max-width: 1300px;
        width: 100%;
        margin: 0 auto;
        box-sizing: border-box;
    `)}
`;


export const Section = styled.section`
	display: flex;
	flex-direction: column; // Stack multiple elements within this section vertically
	max-width: 1300px; // Max width constraint for DESKTOP
	padding: 0 30px; // Horizontal padding for DESKTOP
	box-sizing: border-box; // Include padding in width calculation

	// --- Horizontal Placement Logic using Margins ---
	// Center alignment (default)
	${({ placement }) => (placement === 'center' || !placement) && ` // Default to center if placement is not provided
		margin-left: auto;
        width: 100%;
        align-items: center; // Center flex items (content) horizontally within the section
	`}

	// Left alignment
	${({ placement }) => placement === 'flex-start' && `
		margin-left: 0; // Aligns the section itself to the left
        align-items: flex-start; // Align flex items (content) to the start (left)
	`}

	// Right alignment
	${({ placement }) => placement === 'flex-end' && `
		margin-left: auto;
        align-items: flex-end; // Align flex items (content) to the end (right)
	`}



	// --- Specific Section Styling (Desktop focus) ---
	&.author-section {
		// Example: Override size specifically for author section on desktop
		width: 900px; // Fixed width on larger screens
        max-width: 900px; // Ensure max-width matches if using fixed width
		margin-left: auto;  // Re-center it
		margin-right: auto;
		align-items: center;

		${sizeDesktopSm(` // Example breakpoint usage for slightly smaller desktops
			width: 800px;
            max-width: 800px;
		`)}
        // Mobile/Tablet overrides for this specific section will happen in the general rule below
	}

	// --- General Tablet/Mobile Adjustments ---
	${sizeTabletLg(`
		/* Override desktop styles for smaller screens */
        width: 100%;       // Force the section to take full available width
        max-width: 100%;   // Remove the desktop max-width restriction
		padding: 0 15px;   // Apply mobile-friendly padding

        /* Optional: Reset explicit margins if they interfere with full width */
        /* You might need these if marginLeft/marginRight props cause overflow */
        /* margin-left: 0 !important; */
        /* margin-right: 0 !important; */

        /* Keep placement's align-items property working, or override */
        /* Example: force content centering on mobile regardless of placement prop */
        /* align-items: center; */

        /* Ensure specific section overrides also go full width */
        &.author-section {
            width: 100%; // Explicitly override the desktop fixed width for author section too
            max-width: 100%; // Remove fixed max-width on mobile
            // Centering margins (auto) should still work fine with width 100% if needed,
            // but usually not necessary if the element itself is full width.
            // The align-items property handles content alignment within.
        }
	`)}
`;

export const ColCenter = styled.div`
    width: calc(100% - 280px);
    margin-left: 140px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
`;

export const ColRight = styled.div`
    width: calc(100% - 440px);
    margin: 0 auto;
    ${sizeLaptopLg(`
        width: calc(100% - 320px);
    `)}
    ${sizeTabletLg(`
        width: 100%;
    `)}

    ${sizeWideScreen(`
        width: 100%;
        box-sizing: border-box;
    `)}
`;

export const ColLeft = styled.div`
    width: 440px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 440px;
            flex: 0 0 440px;
    ${sizeLaptopLg(`
        width: 320px;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 320px;
                flex: 0 0 320px;
    `)}
    ${sizeTabletLg(`
        width: 100%;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
                flex: 0 0 100%;
    `)}
`;

export const BlogText = styled.div`
    /* ${sizeTabletLg(`
        height: 400px;
    `)} */
`;
export const TextWithImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
  }

  img {
    width: 100%;
    height: auto;
    margin-bottom: 16px;

    @media (min-width: 768px) {
      width: 45%;
      margin-bottom: 0;
      margin-left: 24px;
    }
  }

  .text-content {
    width: 100%;

    @media (min-width: 768px) {
      width: 50%;
    }
  }
`;
export const BlogImage = styled.img`
    ${sizeTabletLg(`
        max-height: 400px;
    `)}
    margin: 20px 0 40px;
    place-self: center;
    width: 100%;
    object-fit: cover;
    max-height: 630px;
    height: 100%;
`;

export const VideoPoster = styled.img`
    width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: .5s all ease;
`;



export const BlogVideo = styled.div`
    position: relative;
    overflow: hidden;
    ${sizeLaptopLg(`
        height: 220px;
    `)}
    ${sizeTabletLg(`
        height: auto;
    `)}
`;

export const BlogWistiaVideo = styled.div`
    width: 100%;
    max-width: 1900px;
    margin: 0 auto;


`;
export const SectionVideo = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    position: relative;
    ${sizeDesktopSm(`
        flex-direction: column;
        flex-direction: column-reverse;
        max-width: 800px;
    `)}
`;
export const SectionVideoDesc = styled.div`
    padding-left: 0;
    box-sizing: border-box;
    max-width: 100%;
    width: 100%;
    ${sizeTabletLg(`
        margin: 25px 0;
        max-width: 100%;
        padding: 0;
    `)}
`;
export const BlogLeftVideo = styled.div`
    max-width: 440px;
    height: 300px;
    width: 100%;
    position: absolute;
    left: -245px;
    top: 50%;
    transform: translateX(-50%);

    div[id^="wistia_"][id$='.thumb_container'] {
        ${sizeDesktopSm(css`
            position: absolute !important;
            left: 0  !important;
            top: 0  !important;
            width: 100%;
            height: 100% !important;
        `)}
    }
    img[id^="wistia_"][id$='.thumbnail_img'] {
        width: 100% !important;
        left: 0 !important;
        position: relative !important;
        ${sizeDesktopSm(`
            top: 0 !important;
            height: 100%;
        `)}
    }

      @media (max-width: 1900px) and (min-width: 1801px) {
        max-width: 360px;
        left: -230px;
        height: 200px;
      }
      @media (max-width: 1800px) and (min-width: 1701px) {
        max-width: 360px;
        left: -205px;
        height: 200px;
      }
      @media (max-width: 1700px) and (min-width: 1601px) {
        max-width: 300px;
        left: -180px;
        height: 220px;
        height: 170px;
      }
      @media (max-width: 1600px) and (min-width: 1501px) {
        max-width: 260px;
        left: -153px;
        height: 200px;
        height: 170px;
      }
      @media (max-width: 1500px) and (min-width: 1441px) {
        max-width: 240px;
        left: -138px;
        height: 150px;
      }
    ${sizeDesktopSm(css`
        position: relative;
        transform: none;
        left: 0;
        top: auto;
        max-width: 100%;
        height: 100%;
        padding-bottom: 50px;
        .wistia_swatch {
            position: relative !important;
            div {
                width: 100%;
                height: 100%;
            }
        }
    `)}
    ${sizeTabletLg(`
        margin: 0;
        max-width: 100%;
    `)}
    .wistia_custom {
        height: 100% !important;
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.5);
        ${sizeTabletLg(`
            margin: 0;
            max-width: 100%;
        `)}
        div[aria-label=Play] {
          background-color: transparent !important;
          opacity: 1 !important;
          border: none !important;
          border-radius: 50% !important;
          width: 84px !important;
          height: 84px !important;
          position: absolute !important;
          left: 20px !important;
          top: auto !important;
          bottom: 20px !important;
          transform: none !important;
          margin: 0 !important;
          -webkit-box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.15) !important;
          box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.15) !important;
          background-size: 100% 100% !important;
          background-position: center center !important;
          background-image: url("/images/play-button.svg") !important;
        }
          
          div[id^="wistia_"][id$='.big_play_button_background'] {
            display: none;
          }
        button {
            width: 100%;
            height: 100%;
            background-color: transparent !important;
            >div {
                background-color: transparent !important;
            }
        }
        svg {
            position: absolute !important;
            left: 50% !important;
            top: 50% !important;
            transform: translate(-50%, -50%) !important;
        }
        .w-bottom-bar {
            display: none !important;
        }
        div[data-handle=clickForSoundButton] {
            button {
                display: none !important;    
            }
        }
    }
    ${Caption} {
        padding-top: 10px;
        border-top: 1px solid rgba(0, 0, 0, 0.5);
        margin-top: 15px;
        display: block;
    }
`;